import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './pages/inicio/inicio.component';
import { MenuComponent } from './pages/menu/menu.component';
import { EncuentranosComponent } from './pages/encuentranos/encuentranos.component';
import { PromosComponent } from './pages/promos/promos.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { CategoriasListComponent } from './dashboard/categorias/categorias-list/categorias-list.component';
import { ComidasListComponent } from './dashboard/comidas/comidas-list/comidas-list.component';
import { ComidaRegisterComponent } from './dashboard/comidas/comida-register/comida-register.component';
import { ComidaEditComponent } from './dashboard/comidas/comida-edit/comida-edit.component';
import { PromocionesListComponent } from './dashboard/promociones/promociones-list/promociones-list.component';
import { PromocionesRegisterComponent } from './dashboard/promociones/promociones-register/promociones-register.component';
import { LoginComponent } from './dashboard/login/login.component';
import { ComidaComponent } from './componets/comida/comida.component';
// Guard
import { AuthGuardGuard } from './guards/auth-guard.guard';
import { LoggedGuard } from './guards/logged.guard';
import { Notfound404Component } from './componets/notfound404/notfound404.component';


const routes: Routes = [
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'inicio', component: InicioComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'encuentranos', component: EncuentranosComponent },
  { path: 'promos', component: PromosComponent },
  { path: 'contacto', component: ContactoComponent },
  // Rutas del administrador
  { path: 'admin', component: LoginComponent, canActivate: [LoggedGuard] },
  { path: 'admin/categorias', component: CategoriasListComponent, canActivate: [AuthGuardGuard] },
  { path: 'admin/comidas', component: ComidasListComponent, canActivate: [AuthGuardGuard] },
  { path: 'admin/comida-register', component: ComidaRegisterComponent, canActivate: [AuthGuardGuard] },
  { path: 'admin/comida-edit/:id', component: ComidaEditComponent, canActivate: [AuthGuardGuard] },
  { path: 'admin/promociones', component: PromocionesListComponent, canActivate: [AuthGuardGuard] },
  { path: 'admin/promocion-register', component: PromocionesRegisterComponent, canActivate: [AuthGuardGuard] },

  // 404
  { path: '404', component: Notfound404Component },
  { path: '**', component: Notfound404Component }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
