import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-slides',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.css']
})
export class SlidesComponent implements OnInit {
  // Asiganos las variables a los iconos importados desde font awesom
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faTwitter = faTwitter;

  config: SwiperOptions = {
    keyboard: {
      enabled: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    speed: 1000,
    loop: true,
    autoplay: true
  };

  productosSlide = [
    // tslint:disable-next-line:max-line-length
    { image: 'assets/images/hamburguesas.png', nombre: 'Hamburguesa clásica', descripcion: 'Lorem ipsumn dolor sit amet.' },
    { image: 'assets/images/ENSALADAS.png', nombre: 'Chicken Salad', descripcion: 'Lorem ipsumn dolor sit amet.' },
    { image: 'assets/images/PIZZAS.png', nombre: 'Pizza Pepperoni', descripcion: 'Lorem ipsumn dolor sit amet.' }
  ];

  constructor() { }

  ngOnInit() {
  }

}
