import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Categoria } from '../models/categoria.model';
import { AngularFireStorage } from '@angular/fire/storage';
import { ComidasService } from './comidas.service';
import { Comida } from '../models/comida.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {

  constructor(
    public afs: AngularFirestore, // Referencia a Firestore
    private storage: AngularFireStorage,
    private comidaService: ComidasService,
  ) { }


  // Tarea para subir archivo
  public tareaCloudStorage(nombreArchivo: string, datos: any) {
    return this.storage.upload(`categorias/${nombreArchivo}`, datos);
  }

  // Referencia del archivo
  public referenciaCloudStorage(nombreArchivo: string) {
    return this.storage.ref(`categorias/${nombreArchivo}`);
  }

  public getCategorias2() {
    // Consulta para categorias
    return this.afs.collection('categorias', ref => ref.orderBy('createdDate', 'desc'));

  }
  public getCategorias() {
    // Consulta para categorias
    return this.afs.collection('categorias', ref => ref.where('status', '==', true).orderBy('createdDate', 'desc'));

  }

  // Obtiene un categorias
  public getCategoria(cid) {

    return this.afs.collection('categorias').doc(cid);
  }


  // Crea un nueva categoria
  public createCategoria(categoria: Categoria) {
    categoria.createdDate = new Date();
    categoria.status = true;
    return this.afs.collection('categorias').add(categoria);
  }
  // Actualiza una categoria
  public updateCategoria(categoria: Categoria, id, url?) {
    if (url) {
      this.storage.storage.refFromURL(url).delete();
    }

    console.log(id);
    return this.afs.collection('categorias').doc(id).update({
      nombre: categoria.nombre,
      urlImage: categoria.urlImage
    }).then(() => {
      console.log('actualizado correctamento');
    }).catch(err => {
      console.log(err);
    });
  }

  // Desactiva una categoria
  public desactivarCategoria(cid) {

    // this.comidaService.getComidasCategoria(cid);
    // this.comidaService.comidasCat.forEach(com => {
    //   console.log(com);
    // });


    return this.afs.collection('categorias').doc(`${cid}`).update({
      status: false,
    }).then(() => {
      console.log('Categoria desactivada');
    }).catch(err => {
      console.log(err);
    });
  }

  // Activa una categoria
  public activarCategoria(cid) {
    // this.comidaService.getComidasCategoria(cid);
    // this.comidaService.comidasCat.forEach(com => {
    //   console.log(com);
    // });


    return this.afs.collection('categorias').doc(`${cid}`).update({
      status: true,
    }).then(() => {
      console.log('activada categoria');
    }).catch(err => {
      console.log(err);
    });
  }
  // Elimina una categoria

  public deleteCategoria(categoria: Categoria, url) {
    console.log(categoria.id + 'id');
    return this.afs.collection('categorias').doc(categoria.id).delete().then(() => {
      this.storage.storage.refFromURL(url).delete();
      console.log('Document successfully deleted!');
    }).catch((error) => {
      console.error('Error removing document: ', error);
    });

  }
}
