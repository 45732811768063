import { Component, OnInit } from '@angular/core';
import { PromocionesService } from 'src/app/services/promociones.service';

@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.css']
})
export class PromosComponent implements OnInit {
  // Array de promociones
  promociones = [];

  constructor(
    private promocionesService: PromocionesService
  ) { }
  ngOnInit() {
    window.scroll(0, 0);
    // Consulta a categorías
    this.promocionesService.getPromociones().snapshotChanges().subscribe(promociones => {
      this.promociones = [];
      promociones.forEach(promocion => {
        this.promociones.push({
          id: promocion.payload.doc.id,
          data: promocion.payload.doc.data()
        });
      });
    });
  }
}
