import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class ToastService {
  msgs = [];
  constructor(
    private messageService: MessageService,
  ) { }

  add(severityT, summaryT, detailT) {
    this.msgs.push({ severity: severityT, summary: summaryT, detail: detailT });
    window.scroll(0, 0);
  }

  hide() {
    this.msgs = [];
  }

}
