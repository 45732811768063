import { Component, OnInit, OnDestroy } from '@angular/core';
import { CategoriasService } from 'src/app/services/categorias.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Categoria } from 'src/app/models/categoria.model';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-categorias-list',
  templateUrl: './categorias-list.component.html',
  styleUrls: ['./categorias-list.component.css'],
  providers: [MessageService]
})
export class CategoriasListComponent implements OnInit {
  // Foto variables
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  public porcentaje = 0;
  public finalizado = false;
  public URLPublica = '';
  url = '';
  // propiedad para identificar hay un cambio en la imagen
  cambio = false;
  categoriasSuspcription: any;
  // Array de categorias
  categorias = [];
  // ID de categoria
  categoriaId = '';

  // Propiedad para activar / desasctivar el botón
  isCargando = false;

  constructor(
    private categoriasService: CategoriasService, // Servicio de categorias
    public toastService: ToastService,
    private confirmationService: ConfirmationService
  ) { }

  // Variables para controlar el modal
  public modalRegister = false;
  public modalUpdate = false;

  // Form
  public newCategoria = new FormGroup({
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)])
  });
  // Form
  public updateCategoria = new FormGroup({
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)])
  });

  ngOnInit() {
    this.isCargando = false;
    window.scroll(0, 0);
    // Consulta a categorías
    this.categoriasSuspcription = this.categoriasService.getCategorias2().snapshotChanges().subscribe(categorias => {
      this.categorias = [];
      categorias.forEach(categoria => {
        this.categorias.push({
          id: categoria.payload.doc.id,
          data: categoria.payload.doc.data()
        });
      });
    });
  }

  // Imagen

  fileProgress(fileInput: any) {
    this.cambio = true;
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
  }


  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    // tslint:disable-next-line:variable-name
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }



  // Método para desplegar el modal de registro
  showModalRegister() {
    this.isCargando = false;
    this.fileData = null;
    this.fileUploadProgress = null;
    this.uploadedFilePath = null;
    this.finalizado = false;
    this.URLPublica = '';
    this.previewUrl = null;
    this.porcentaje = 0;
    this.modalRegister = true;
    this.newCategoria.patchValue({
      nombre: ''
    });
  }
  // Método para cerrar modal
  closeModalRegister() {
    this.modalRegister = false;
  }

  // Registro

  submitCategoriaData() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    const archivo = formData.get('file');
    this.fileUploadProgress = '0%';
    const anexo = Math.round(Math.random() * (100 - 1) + 1);
    const referencia = this.categoriasService.referenciaCloudStorage(this.fileData.name + anexo);
    const tarea = this.categoriasService.tareaCloudStorage(this.fileData.name + anexo, archivo);
    this.finalizado = true;
    // Cambia el porcentaje
    tarea.percentageChanges().subscribe((porcentaje) => {
      this.isCargando = true;
      this.fileUploadProgress = Math.round(porcentaje / porcentaje * 100) + '%';
      if (this.porcentaje === 100) {
      }
    });

    tarea.then(res => {
      referencia.getDownloadURL()
        .subscribe((URL) => {
          this.URLPublica = URL;
          console.log(this.URLPublica);
          const categoria = this.newCategoria.value;
          categoria.urlImage = this.URLPublica;
          this.categoriasService.createCategoria(categoria).then(() => {
            this.toastService.add('success', '¡Registro exitoso!', 'Categoría registrada con éxito');
            this.closeModalRegister();
            setTimeout(r => {
              this.isCargando = false;
              this.toastService.hide();
            }, 2000);
          }
          ).catch(err => {
            console.log(err);
            this.toastService.add('error', '¡Ha ocurrido un error!', err);
          });
        });
    });
  }


  // Update

  showModalUpdate(cid) {
    this.modalUpdate = true;
    console.log('me estoy mostrando');
    this.cambio = false;
    this.categoriasService.getCategoria(cid).valueChanges().subscribe((data: any) => {
      this.url = data.urlImage;
      this.previewUrl = data.urlImage;
      this.URLPublica = data.urlImage;
      this.updateCategoria.patchValue({
        nombre: data.nombre
      });
      console.log(data.nombre);
    });
    this.categoriaId = cid;
    console.log(this.categoriaId, ' Categoria ID');
    console.log(cid);
  }

  // Método para cerrar modal
  closeModalUpdate() {
    this.modalUpdate = false;
  }

  updateCategoriaData() {
    if (this.cambio === true) {
      const formData = new FormData();
      formData.append('file', this.fileData);
      const archivo = formData.get('file');
      this.fileUploadProgress = '0%';
      const anexo = Math.round(Math.random() * (100 - 1) + 1);
      this.isCargando = true;
      const referencia = this.categoriasService.referenciaCloudStorage(this.fileData.name + anexo);
      const tarea = this.categoriasService.tareaCloudStorage(this.fileData.name + anexo, archivo);

      // Cambia el porcentaje
      tarea.percentageChanges().subscribe((porcentaje) => {
        this.fileUploadProgress = Math.round(porcentaje) + '%';
        if (this.porcentaje === 100) {
          this.finalizado = true;
        }
      });

      tarea.then(res => {
        referencia.getDownloadURL()
          .subscribe((URL) => {
            this.URLPublica = URL;
            console.log(this.URLPublica);
            const categoria = {} as Categoria;
            categoria.urlImage = this.URLPublica;
            categoria.nombre = this.updateCategoria.value.nombre;
            this.categoriasService.updateCategoria(categoria, this.categoriaId, this.url).then(() => {
              this.closeModalUpdate();
              this.toastService.add('success', '¡Actualización exitosa!', 'Categoría actualizada con éxito');
              setTimeout(r => {
                this.isCargando = false;
                this.toastService.hide();
              }, 2000);
            }).catch(err => {
              console.log(err);
              this.toastService.add('error', '¡Ha ocurrido un error!', err);

            });
          });
      });
    } else {
      const categoria = {} as Categoria;
      categoria.urlImage = this.URLPublica;
      categoria.nombre = this.updateCategoria.value.nombre;
      this.categoriasService.updateCategoria(categoria, this.categoriaId).then(() => {
        this.closeModalUpdate();
        this.toastService.add('success', '¡Actualización exitosa!', 'Categoría actualizada con éxito');
        setTimeout(r => {
          this.toastService.hide();
        }, 2000);
      }).catch(err => {
        this.toastService.add('error', '¡Ha ocurrido un error!', err);
        console.log(err);
      });
    }
  }
  confirm(categoria, url) {
    this.confirmationService.confirm({
      message: '¿Estás seguro de que deseas eliminar la categoría?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Sí',
      accept: () => {
        this.deleteCategoria(categoria, url);
      }
    });
  }

  deleteCategoria(categoria, url) {
    this.categoriasService.deleteCategoria(categoria, url).then(() => {
      this.toastService.add('error', '¡Eliminación exitosa!', 'Categoría eliminada con éxito');
      setTimeout(r => {
        this.toastService.hide();
      }, 2000);
      console.log('eliminado');
    }).catch(err => {
      console.log(err);
      this.toastService.add('error', '¡Ha ocurrido un error!', err);
    });

  }

  // Activar Categoría
  activarCategoria(cid) {
    this.categoriasService.activarCategoria(cid).then(() => {
      this.toastService.add('success', '¡Activación exitosa!', 'Categoría activada con éxito');
      setTimeout(r => {
        this.toastService.hide();
      }, 2000);
      console.log('Activada');
    }).catch(err => {
      console.log(err);
      this.toastService.add('error', '¡Ha ocurrido un error!', err);
    });
  }

  // Desactivar Categoría
  desactivarCategoria(cid) {
    this.categoriasService.desactivarCategoria(cid).then(() => {
      this.toastService.add('error', '¡Desactivación exitosa!', 'Categoría desactivada con éxito');
      setTimeout(r => {
        this.toastService.hide();
      }, 2000);
      console.log('Desactivada desde el componente');
    }).catch(err => {
      console.log(err);
      this.toastService.add('error', '¡Ha ocurrido un error!', err);
    });
  }

  // Getters
  get nombre() {
    return this.newCategoria.get('nombre');
  }

  get nombre2() {
    return this.updateCategoria.get('nombre');
  }

  // ngOnDestroy(): void {
  //  this.categoriasSuspcription.unsubcribe();
  // }

}
