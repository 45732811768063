import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Promocion } from '../models/promocion.model';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class PromocionesService {

  constructor(
    public afs: AngularFirestore, // Referencia a Firestore
    private storage: AngularFireStorage
  ) { }

  // Tarea para subir archivo
  public tareaCloudStorage(nombreArchivo: string, datos: any) {
    return this.storage.upload(`promociones/${nombreArchivo}`, datos);
  }

  // Referencia del archivo
  public referenciaCloudStorage(nombreArchivo: string) {
    return this.storage.ref(`promociones/${nombreArchivo}`);
  }


  public getPromociones() {
    // Consulta para promociones
    return this.afs.collection('promociones', ref => ref.orderBy('createdDate', 'desc'));

  }

  // Obtiene un promociones
  public getPromocion(cid) {

    return this.afs.collection('promociones').doc(cid);
  }


  // Crea un nueva Promocion
  public createPromocion(promocion: Promocion) {
    promocion.createdDate = new Date();
    promocion.status = true;
    return this.afs.collection('promociones').add(promocion);
  }
  // Elimina una Promocion

  public deletePromocion(pid, url) {
    return this.afs.collection('promociones').doc(pid).delete().then(() => {
      this.storage.storage.refFromURL(url).delete();
      console.log('Document successfully deleted!');
    }).catch((error) => {
      console.error('Error removing document: ', error);
    });

  }
}
