// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: 'AIzaSyAlgxvruU9nHXNxbvSzZvJ8xB-Jv01Pa68',
    authDomain: 'xbxpress-4023f.firebaseapp.com',
    databaseURL: 'https://xbxpress-4023f.firebaseio.com',
    projectId: 'xbxpress-4023f',
    storageBucket: 'xbxpress-4023f.appspot.com',
    messagingSenderId: '1097407349175',
    appId: '1:1097407349175:web:432599f5a83297d44da832',
    measurementId: 'G-RWG7HNHGQ6'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
