import { Component, OnInit, OnDestroy } from '@angular/core';
import { CategoriasService } from 'src/app/services/categorias.service';
import { ComidasService } from 'src/app/services/comidas.service';
import {ConfirmationService } from 'primeng/api';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-comidas-list',
  templateUrl: './comidas-list.component.html',
  styleUrls: ['./comidas-list.component.css'],
})
export class ComidasListComponent implements OnInit {

  categoriasSuspcription: any;
  comidasSubscription: any;
  // Array de categorias
  categorias = [];
  // Array de platillos
  comidas = [];
  constructor(
    private categoriasService: CategoriasService, // Servicio de categorias
    private comidasService: ComidasService,
    public toastService: ToastService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    // Consulta a categorías
    this.categoriasSuspcription = this.categoriasService.getCategorias().snapshotChanges().subscribe(categorias => {
      this.categorias = [];
      categorias.forEach(categoria => {
        this.categorias.push({
          id: categoria.payload.doc.id,
          data: categoria.payload.doc.data()
        });
      });
    });



    // Consulta a platillos
    this.comidasSubscription = this.comidasService.getComidas().snapshotChanges().subscribe(comidas => {
      this.comidas = [];
      comidas.forEach(comida => {
        this.comidas.push({
          id: comida.payload.doc.id,
          data: comida.payload.doc.data()
        });
      });
      console.log(this.comidas);
    });
  }
  confirm(comida) {
    this.confirmationService.confirm({
        message: '¿Estás seguro de que deseas eliminar el platillo?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Sí',
        accept: () => {
           this.deleteComida(comida);
        }
    });
}

  // Eliminar platillo

  deleteComida(comida) {
    this.comidasService.deleteComida(comida).then(() => {
      this.toastService.add('error', '¡Eliminación exitosa!', 'Platillo eliminado con éxito');
      setTimeout(r => {
        this.toastService.hide();
      }, 2000);
      console.log('elimidado');
    }).catch(err => {
      console.log(err);
    });
  }

  //   ngOnDestroy(): void {
  //     console.log('me destruí');
  //     this.categoriasSuspcription.unsubcribe();
  //     this.comidasSubscription.unsubcribe();
  //  }


}
