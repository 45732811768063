import { Component, OnInit } from '@angular/core';
import { CategoriasService } from 'src/app/services/categorias.service';
import { ComidasService } from 'src/app/services/comidas.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-comida-edit',
  templateUrl: './comida-edit.component.html',
  styleUrls: ['./comida-edit.component.css']
})
export class ComidaEditComponent implements OnInit {
  // Array de categorias
  categorias = [];
  // Identificador del platillo a editar
  platilloId = '';

  constructor(
    private categoriasService: CategoriasService, // Servicio de categorias
    private comidasService: ComidasService,
    public router: Router,
    private actRoute: ActivatedRoute,
    private toastService: ToastService
  ) { }

  // Form
  public platilloForm = new FormGroup({
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
    descripcion: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(1000)]),
    precio: new FormControl('', [Validators.required, Validators.maxLength(8), Validators.min(0)]),
    categoriaId: new FormControl('', Validators.required)
  });


  ngOnInit() {
    window.scroll(0, 0);
    this.platilloId = this.actRoute.snapshot.paramMap.get('id');

    // Consulta a categorías
    this.categoriasService.getCategorias().snapshotChanges().subscribe(categorias => {
      this.categorias = [];
      categorias.forEach(categoria => {
        this.categorias.push({
          id: categoria.payload.doc.id,
          data: categoria.payload.doc.data()
        });
      });
    });

    // Consulta a platillo
    console.log(this.platilloId);
    this.comidasService.getComida(this.platilloId).valueChanges().subscribe((platillo: any) => {
      console.log(platillo);
      this.platilloForm.patchValue({
        nombre: platillo.nombre,
        descripcion: platillo.descripcion,
        precio: platillo.precio,
        categoriaId: platillo.categoriaId
      });
    });
  }

  submitPlatilloData() {
    this.comidasService.updateComida(this.platilloForm.value, this.platilloId).then(() => {
      this.router.navigate(['/admin/comidas']).then(() => {
        this.toastService.add('success', '¡Actualización exitosa!', 'Platillo actualizado con éxito');
        setTimeout(r => {
          this.toastService.hide();
        }, 2000);
      });
    }).catch(err => {
      console.log(err);
    });
  }

  // Getters
  get nombre() {
    return this.platilloForm.get('nombre');
  }

  get descripcion() {
    return this.platilloForm.get('descripcion');
  }


  get precio() {
    return this.platilloForm.get('precio');
  }
}
