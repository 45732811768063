import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-encuentranos',
  templateUrl: './encuentranos.component.html',
  styleUrls: ['./encuentranos.component.css']
})
export class EncuentranosComponent implements OnInit {


  texto = 'Wenceslau Braz - Cuidado com as cargas';
  lat = 21.126938;
  lng = -86.877768;
  zoom = 16;

  markers = [
    // These are all just random coordinates from https://www.random.org/geographic-coordinates/
    { lat: 21.126938, lng: -86.877768, alpha: 1 },
  ];

  iconMap = {
    // tslint:disable-next-line:max-line-length
    url: '../../../assets/images/logo2.png',
    scaledSize: {
      width: 50,
      height: 55
    }
  };


  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
  }

}
