import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NavbarComponent } from './componets/navbar/navbar.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { SlidesComponent } from './componets/slides/slides.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { MenuComponent } from './pages/menu/menu.component';
import { FooterComponent } from './componets/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EncuentranosComponent } from './pages/encuentranos/encuentranos.component';
import { PromosComponent } from './pages/promos/promos.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { HeroComponent } from './componets/hero/hero.component';
import { ProductoComponent } from './componets/producto/producto.component';

// Mapa Module
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
//
import { environment } from 'src/environments/environment';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { CategoriasListComponent } from './dashboard/categorias/categorias-list/categorias-list.component';
import { CategoriasService } from './services/categorias.service';
import { ComidasListComponent } from './dashboard/comidas/comidas-list/comidas-list.component';
import { ComidasService } from './services/comidas.service';
import { ComidaRegisterComponent } from './dashboard/comidas/comida-register/comida-register.component';
import { ComidaEditComponent } from './dashboard/comidas/comida-edit/comida-edit.component';
import { PromocionesListComponent } from './dashboard/promociones/promociones-list/promociones-list.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { PromocionesRegisterComponent } from './dashboard/promociones/promociones-register/promociones-register.component';
import { LoginComponent } from './dashboard/login/login.component';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { ComidaComponent } from './componets/comida/comida.component';
import { Notfound404Component } from './componets/notfound404/notfound404.component';
// Recaptcha
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

// Prime
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { PromocionesService } from './services/promociones.service';
import { EmailService } from './services/email.service';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    InicioComponent,
    SlidesComponent,
    MenuComponent,
    FooterComponent,
    EncuentranosComponent,
    PromosComponent,
    ContactoComponent,
    HeroComponent,
    ProductoComponent,
    CategoriasListComponent,
    ComidasListComponent,
    ComidaRegisterComponent,
    ComidaEditComponent,
    PromocionesListComponent,
    PromocionesRegisterComponent,
    LoginComponent,
    ComidaComponent,
    Notfound404Component,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxUsefulSwiperModule,
    FontAwesomeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCICaIFllXKHVKDr0lY281Y_9vbi4TNqGw',
      libraries: ['places']
    }),
    AgmSnazzyInfoWindowModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // SendgridModule.forRoot({token: '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'})
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MessageModule,
    MessagesModule,
    ConfirmDialogModule,
  ],
  providers: [
    CategoriasService,
    ComidasService,
    PromocionesService,
    EmailService,
    MessageService,
    ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
