import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  loading = true;

  constructor() {
  }

  ngOnInit() {
    this.loading = true;
    // console.log(this.loading);
    setTimeout(() => {
      this.loading = false;
      // console.log(this.loading);
    }, 1000);
  }


}
