import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})
export class HeroComponent implements OnInit {

  @Input() title: string;
  @Input() imgClass: string;
  @Input() titleClass: string;

  test = 'img-bg';

  constructor() { }


  ngOnInit() {
    if (!this.imgClass) {
      this.imgClass = this.test;
    }
    console.log(this.imgClass);
  }

}
