import { Component, OnInit } from '@angular/core';
import { CategoriasService } from 'src/app/services/categorias.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ComidasService } from 'src/app/services/comidas.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-comida-register',
  templateUrl: './comida-register.component.html',
  styleUrls: ['./comida-register.component.css']
})
export class ComidaRegisterComponent implements OnInit {
  // Array de categorias
  categorias = [];

  // Propiedad para activar / desasctivar el botón
  isCargando = false;

  constructor(
    private categoriasService: CategoriasService, // Servicio de categorias
    private comidasService: ComidasService,
    public router: Router,
    private toastService: ToastService
  ) { }

  // Form
  public platilloForm = new FormGroup({
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
    descripcion: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(1000)]),
    precio: new FormControl('', [Validators.required, Validators.maxLength(8), Validators.min(0)]),
    categoriaId: new FormControl('', Validators.required)
  });

  ngOnInit() {
    window.scroll(0, 0);
    // Consulta a categorías
    this.categoriasService.getCategorias().snapshotChanges().subscribe(categorias => {
      this.categorias = [];
      categorias.forEach(categoria => {
        this.categorias.push({
          id: categoria.payload.doc.id,
          data: categoria.payload.doc.data()
        });
      });
    });
  }

  submitPlatilloData() {
    this.isCargando = true;
    this.comidasService.createComida(this.platilloForm.value).then(() => {
      this.router.navigate(['/admin/comidas']).then(() => {
        this.toastService.add('success', '¡Registro exitoso!', 'Platillo registrado con éxito');
        setTimeout(r => {
          this.isCargando = false;
          this.toastService.hide();
        }, 2000);
      });
    }).catch(err => {
      console.log(err);
    });
  }


  // Getters
  get nombre() {
    return this.platilloForm.get('nombre');
  }

  get descripcion() {
    return this.platilloForm.get('descripcion');
  }


  get precio() {
    return this.platilloForm.get('precio');
  }
}
