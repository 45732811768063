import { Component, OnInit, OnDestroy } from '@angular/core';
import { PromocionesService } from 'src/app/services/promociones.service';
import { ToastService } from 'src/app/services/toast.service';
import {ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-promociones-list',
  templateUrl: './promociones-list.component.html',
  styleUrls: ['./promociones-list.component.css']
})
export class PromocionesListComponent implements OnInit {

  promocionesSusbcription: any;
  // Array de promociones
  promociones = [];

  constructor(
    private promocionesService: PromocionesService, // Servicio de promociones
    public toastService: ToastService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    // Consulta a categorías
    this.promocionesSusbcription = this.promocionesService.getPromociones().snapshotChanges().subscribe(promociones => {
      this.promociones = [];
      promociones.forEach(promocion => {
        this.promociones.push({
          id: promocion.payload.doc.id,
          data: promocion.payload.doc.data()
        });
      });
    });
  }

  confirm(pid, url) {
    this.confirmationService.confirm({
        message: '¿Estás seguro de que deseas eliminar la promoción?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Sí',
        accept: () => {
           this.deletePromo(pid, url);
        }
    });
}

  deletePromo(pid, url) {
    this.promocionesService.deletePromocion(pid, url).then(() => {
      this.toastService.add('error', '¡Eliminación exitosa!', 'Promoción eliminada con éxito');
      setTimeout(r => {
        this.toastService.hide();
      }, 2000);
    }).catch(err => {
      console.log(err);
    });
  }

  // ngOnDestroy(): void {
  //  this.promocionesSusbcription.unsubscibe();
  // }

}
