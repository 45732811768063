import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Comida } from '../models/comida.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComidasService {

  comidasCat = [];
  constructor(
    public afs: AngularFirestore, // Referencia a Firestore
  ) { }


  public getComidas() {
    // Consulta para comidas
    return this.afs.collection('comidas', ref => ref.orderBy('createdDate', 'desc'));

  }

  public getComidasCategoria(ccid) {
    // Consulta para comidas
     this.afs.collection('comidas', ref => ref.where('categoriaId', '==', ccid)).snapshotChanges().pipe(map(com => {
      this.comidasCat = com.map(p => {
        const data = p.payload.doc.data();
        const id = p.payload.doc.id;
        return { id, ...data };
      });

      return this.comidasCat;

    }));
  }

  // Obtiene un comidas
  public getComida(cid) {

      return this.afs.collection('comidas').doc(cid);
    }

  // activar comida
  public activarComida(cid) {


      return this.afs.collection('comidas').doc(`${cid}`).update({
        status: true,
      }).then(() => {
        console.log('activado platillo');
      }).catch(err => {
        console.log(err);
      });
    }
  // desactivar
  public desactivarComida(cid) {
      return this.afs.collection('comidas').doc(`${cid}`).update({
        status: false,
      }).then(() => {
        console.log('platillo desactivado');
      }).catch(err => {
        console.log(err);
      });
    }

  // Crea un nueva Comida
  public createComida(comida: Comida) {
      comida.createdDate = new Date();
      return this.afs.collection('comidas').add(comida
      );
    }
  // Actualiza una Comida
  public updateComida(comida: Comida, id) {
      console.log(id);
      return this.afs.collection('comidas').doc(id).update({
        nombre : comida.nombre,
        descripcion: comida.descripcion,
        precio: comida.precio,
        categoriaId: comida.categoriaId
      }).then(() => {
        console.log('actualizado correctamento');
      }).catch(err => {
        console.log(err);
      });
    }
  // Elimina una Comida

  public deleteComida(comida: Comida) {
      console.log(comida.id + 'id');
      return this.afs.collection('comidas').doc(comida.id).delete().then(() => {
        console.log('Document successfully deleted!');
      }).catch((error) => {
        console.error('Error removing document: ', error);
      });

    }
}
