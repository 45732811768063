import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: Observable<firebase.User>;


  constructor(
    private angularFireAuth: AngularFireAuth,
    public ngZone: NgZone,
    public router: Router) {
    this.userData = angularFireAuth.authState;
    // Mantener sesión
    this.angularFireAuth.authState.subscribe(user => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  /* Sign in */
  SignIn(email: string, password: string) {
    this.angularFireAuth
      .auth
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        console.log('Successfully signed in!');

        this.ngZone.run(() => {
          this.router.navigate(['/admin/comidas']);
        });
      })
      .catch(err => {
        console.log('Something is wrong:', err.message);
      });
  }

  // Método que nos indica si el usuarios está logueado || booleano
  get isLoggerIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  /* Sign out */
  SignOut() {
    this.angularFireAuth
      .auth
      .signOut().then(res => {
        localStorage.removeItem('user');
        this.ngZone.run(() => {
          this.router.navigate(['/inicio']);
        });
      }).catch(err => {
        console.log(err);
      });
  }
}
