import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  // URL del Formspree
  endPoint = 'https://formspree.io/xqkekaln';

  // Header options
  httpOptions = {
    headers: new HttpHeaders({
      // tslint:disable-next-line:object-literal-key-quotes
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  };

  constructor(private http: HttpClient) { }

  // Método que permite enviar el correo al endpoint de Formspree
  // tslint:disable-next-line:variable-name
  enviarCorreo(_data) {
    return this.http.post(this.endPoint, _data, this.httpOptions);
  }

}
