import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { CategoriasService } from 'src/app/services/categorias.service';
import { ComidasService } from 'src/app/services/comidas.service';

import { from } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { faTheRedYeti } from '@fortawesome/free-brands-svg-icons';
import Swiper, { SwiperOptions } from 'swiper';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Categoria } from 'src/app/models/categoria.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {

  @ViewChild('viewMe', { static: false }) viewMe?: ElementRef<HTMLElement>;

  // Arrays que contendrán las distintas categorías de comidas del menú
  comidas = [];
  comidasFiltradas = [];
  categorias = [];
// variable que sirva para verificar que la categoría es Toppings
  toppings = 'Toppings';
// Variables de redes sociales
  cargando = false;
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faTwitter = faTwitter;

// Categoría con dummy data para el slide
  categoriaFake: Categoria = {
    id: 'ids',
    nombre: 'Categoría',
    createdDate: new Date(),
    status: true,
    urlImage: ''
  };

// Swiper del slide
  mySwiper: Swiper;

  constructor(
    private categoriasService: CategoriasService,
    private comidasService: ComidasService,
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.cargando = true;
    // Consulta a categorías
    this.categoriasService.getCategorias().snapshotChanges().subscribe(categorias => {
      this.categorias = [];
      categorias.forEach(categoria => {
        this.categorias.push({
          id: categoria.payload.doc.id,
          data: categoria.payload.doc.data()
        });
        this.cargando = false;
      });
      // tslint:disable-next-line:no-unused-expression
      // console.log(this.categorias);
      // this.tabActiva = this.categorias[0].data.nombre;
      // console.log(this.tabActiva);

      // Agrega de ultimo categoría con data dummy para el slide
      this.categorias.push({
        id: this.categoriaFake.id,
        data: this.categoriaFake
      });

    });
    // Consulta a platillos
    this.comidasService.getComidas().snapshotChanges().subscribe(comidas => {
      this.comidas = [];
      comidas.forEach(comida => {
        this.comidas.push({
          id: comida.payload.doc.id,
          data: comida.payload.doc.data()
        });
      });
    });
  }

  // Método para configurar el slide después de la carga de los componentes HTML
  ngAfterViewInit(): void {
    console.log(this.categorias.length, '   numero de categorias');
    // Swiper configuraciones
    this.mySwiper = new Swiper('.swiper-container', {
      keyboard: {
        enabled: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      speed: 2000,
      autoplay: {
        disableOnInteraction: false,
        delay: 3000
      },
      observer: true,
      loop: true,
      // Método que permite el reinicio del slide
      on: {
        slideChange() {
          // último elemento visible del slide
          const lastVisibleItem = this.realIndex + 1;
          // tamaño del array de slides
          const slidesLength = this.slides.length - 1;
          console.log( lastVisibleItem, slidesLength);
          // Si el Swiper llega al último index (categoría dummy), regresa al slide 0
          if (lastVisibleItem > slidesLength) {
            this.slideTo(0);
          }
        }

      }
    });
  }

  // Método de redireccionamiento hacia la slide de Toppings
  toppingsSlide() {
    this.mySwiper.slideTo(2);

  }

  // Método para cambiar entre tabs
  // openTab(evt, tabName) {
  //   this.tabActiva = tabName;
  //   console.log(tabName);
  //   // tslint:disable-next-line:one-variable-per-declaration
  //   let i, x, tablinks;
  //   x = document.getElementsByClassName('content-tab');
  //   for (i = 0; i < x.length; i++) {
  //     x[i].style.display = 'none';
  //   }

  //   tablinks = document.getElementsByClassName('tab');
  //   for (i = 0; i < x.length; i++) {
  //     tablinks[i].className = tablinks[i].className.replace(' is-active', '');
  //   }
  //   document.getElementById(tabName).style.display = 'block';
  //   evt.currentTarget.className += ' is-active ';

  // const c = from(this.comidas);
  // this.categorias.forEach(cat => {
  //   console.log(cat);
  //   const r = c.pipe(filter(com => com.data.categoriaId === cat.id));
  //   r.subscribe(com => {
  //     this.comidasFiltradas.push(com);
  //   });
  //   // });
  // }



// Verifica si la categoría es HAMBURGUESAS DE RES
  // tslint:disable-next-line:ban-types
  verificarCategoria(hamburguesa: String) {
    const categoria = 'Hamburguesas de res';
    if (hamburguesa.trim() === categoria.trim()) {
      return true;
    } else {
      return false;
    }
  }

// Varifica si la categoría es HAMBURGUESAS DE POLLO
  // tslint:disable-next-line:ban-types
  verificarCategoria2(hamburguesa: String) {
    const categoria = 'Hamburguesas de pollo';
    if (hamburguesa.trim() === categoria.trim()) {
      return true;
    } else {
      return false;
    }
  }


}
