import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
// import { SendgridService } from 'ngx-sendgrid';
import { HttpClient } from '@angular/common/http';
import { EmailService } from 'src/app/services/email.service';
import { ToastService } from 'src/app/services/toast.service';

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  public formModel: FormModel = {};
  // Asiganos las variables a los iconos importados desde font awesom
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faTwitter = faTwitter;

  // Variable de formulario
  conctactForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private service: EmailService,
    public toastService: ToastService
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    // Inicializamos el form
    this.crearForm();
  }

  // Método que permite crear el formulario
  crearForm() {
    this.conctactForm = this.fb.group({
      nombre: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      mensaje: ['', Validators.required],
      recaptchaReactive: [null, Validators.required]
    });
  }

  sendEmail() {
    const nombre = this.conctactForm.value.nombre;
    const telefono = this.conctactForm.value.telefono;
    const correo = this.conctactForm.value.correo;
    const mensaje = this.conctactForm.value.mensaje;

    const data = `Nombre=${nombre}&Correo=${correo}&Mensaje=${mensaje}&Teléfono=${telefono}`;

    this.service.enviarCorreo(data).subscribe((r: EmailRes) => {
      if (r.ok) {
        this.toastService.add('success', '¡Mensaje enviado correctamente!', 'Gracias, recibirás pronta respuesta');
        // tslint:disable-next-line:no-shadowed-variable
        setTimeout(r => {
          this.toastService.hide();
        }, 2000);
        this.conctactForm.reset();
      } else {
        this.toastService.add('error', '¡Mensaje no enviado!', 'Oops Algo salió mal');
        // tslint:disable-next-line:no-shadowed-variable
        setTimeout(r => {
          this.toastService.hide();
        }, 2000);
      }
    });
  }
}

// Representa la respuesta de la petición, se usa para validar el envío y mostrar un mensaje al usuario.
export interface EmailRes {
  next?: string;
  ok: boolean;
}

