import { Component, OnInit } from '@angular/core';
import { PromocionesService } from 'src/app/services/promociones.service';
import { Router } from '@angular/router';
import { Promocion } from 'src/app/models/promocion.model';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-promociones-register',
  templateUrl: './promociones-register.component.html',
  styleUrls: ['./promociones-register.component.css']
})
export class PromocionesRegisterComponent implements OnInit {
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  public porcentaje = 0;
  public finalizado = false;
  public URLPublica = '';

  activarBoton = false;
  constructor(
    private promocionesService: PromocionesService,
    public router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
  }

  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
    this.activarBoton = true;
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    // tslint:disable-next-line:variable-name
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }


  submitPromocion() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    const archivo = formData.get('file');
    this.fileUploadProgress = '0%';
    const anexo = Math.round(Math.random() * (100 - 1) + 1);
    const referencia = this.promocionesService.referenciaCloudStorage(this.fileData.name + anexo);
    const tarea = this.promocionesService.tareaCloudStorage(this.fileData.name + anexo, archivo);

    // Cambia el porcentaje
    tarea.percentageChanges().subscribe((porcentaje) => {
      this.activarBoton = false;
      this.fileUploadProgress = Math.round(porcentaje) + '%';
      if (this.porcentaje === 100) {
        this.finalizado = true;
      }
    });

    tarea.then(res => {
      referencia.getDownloadURL()
        .subscribe((URL) => {
          this.URLPublica = URL;
          console.log(this.URLPublica);
          const promocion = {} as Promocion;
          promocion.url = this.URLPublica;
          this.promocionesService.createPromocion(promocion).then(() => {

          }
          ).catch(err => {
            console.log(err);
          });
        });

      this.router.navigate(['/admin/promociones']).then(() => {
        this.toastService.add('success', '¡Registro exitoso!', 'Promoción registrada con éxito');
        setTimeout(r => {
          this.toastService.hide();
        }, 2000);
        this.activarBoton = false;

      });
    });

  }

}
